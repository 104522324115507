Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.search = "Search";
exports.callRecordings = "Call Recordings";
exports.view = "View";
exports.delete = "Delete";
exports.enterRecordingId = "Enter recording id";
exports.enterRoomId = "Enter room id";
exports.getRecordings = "Get Recordings";
exports.roomIdEmpty = "Room id is empty";
exports.recordingIdEmpty = "Recording id is empty";
exports.error = "Error";
exports.success = "Success";
exports.noRecording = "No recording found!";
exports.close = "Close";
exports.downloadRecording = "Download Recording";
exports.download = "Download";
exports.recordingDetails = "Recording Details";
exports.fileDownloadSuccess = "File is successfuly downloaded";
exports.fileDownloadFail = "Error occurred while downloading file";

exports.getAllRecordsEndpoint = "video_call/recordings_list/:room_id";
exports.getRecordingEndpoint = "video_call/recordings/:recording_id";
exports.deleteRecordingEndpoint = "video_call/recordings/:recording_id";
// Customizable Area End
