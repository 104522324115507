import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface Recording {
  userId: string;
  roomId: string;
  sessionId: string;
  fileId: string;
  file: {
    meta: {
      resolution: {
        width: number;
        height: number;
      };
      format: string;
      duration: number;
    };
  };
  size: number;
  filePath: string;
  ratio: {
    [key: string]: number;
  };
  fileUrl: string;
  type: string;
  id: string;
}

export interface RecordingDetail {
  userId: string;
  roomId: string;
  sessionId: string;
  fileId: string;
  resolution: string;
  format: string;
  duration: number;
  size: number;
  filePath: string;
  fileUrl: string;
  type: string;
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  recordingId: string;
  roomId: string;
  allRecordings: Array<Recording>;
  recordingDetailModalOpen: boolean;
  deleteConfirmationModalOpen: boolean;
  loading: boolean;
  roomIdError: string;
  recordingIdError: string;
  recordingDetail: RecordingDetail;
  recordingIdToDelete: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CallRecordingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllRecordsCallId: string = "";
  getRecordingCallId: string = "";
  deleteRecordingCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      recordingId: "",
      roomId: "",
      allRecordings: [],
      recordingDetailModalOpen: false,
      deleteConfirmationModalOpen: false,
      loading: false,
      roomIdError: "",
      recordingIdError: "",
      recordingDetail: {} as RecordingDetail,
      recordingIdToDelete: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token: string = message.getData(
        getName(MessageEnum.SessionResponseToken)
      );
      runEngine.debugLog("TOKEN", token);
      if (token) {
        this.setState({ token });
      }
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      apiRequestCallId === this.getAllRecordsCallId
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && !responseJson.error) {
        this.setState({ loading: false, allRecordings: responseJson.data });
      } else if (
        responseJson &&
        responseJson.error &&
        responseJson.error.message
      ) {
        this.setState({ loading: false, allRecordings: [] }, () =>
          this.showAlert(configJSON.error, responseJson.error.message)
        );
      } else {
        this.setState({ loading: false, allRecordings: [] }, () =>
          this.showAlert(configJSON.error, errorReponse)
        );
      }
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      apiRequestCallId === this.getRecordingCallId
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && !responseJson.error) {
        this.setState({ loading: false, allRecordings: [responseJson] });
      } else if (
        responseJson &&
        responseJson.error &&
        responseJson.error.message
      ) {
        this.setState({ loading: false, allRecordings: [] }, () =>
          this.showAlert(configJSON.error, responseJson.error.message)
        );
      } else {
        this.setState({ loading: false, allRecordings: [] }, () =>
          this.showAlert(configJSON.error, errorReponse)
        );
      }
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      apiRequestCallId === this.deleteRecordingCallId
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && !responseJson.error) {
        this.setState({ loading: false }, () => this.handleDeleteSuccess());
      } else if (
        responseJson &&
        responseJson.error &&
        responseJson.error.message
      ) {
        this.setState({ loading: false }, () =>
          this.showAlert(configJSON.error, responseJson.error.message)
        );
      } else {
        this.setState({ loading: false }, () =>
          this.showAlert(configJSON.error, errorReponse)
        );
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getToken();
    // Customizable Area End
  }

  // Customizable Area Start
  getToken = () => {
    const sessionRequestMessage: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(sessionRequestMessage);
  };

  getAllRecordings = () => {
    if (!Boolean(this.state.roomId)) {
      this.setState({ roomIdError: configJSON.roomIdEmpty });
      return;
    }

    this.setState({ loading: true, roomIdError: "" });

    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllRecordsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllRecordsEndpoint.replace(":room_id", this.state.roomId)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getRecording = () => {
    if (!Boolean(this.state.recordingId)) {
      this.setState({ recordingIdError: configJSON.recordingIdEmpty });
      return;
    }

    this.setState({ loading: true, recordingIdError: "" });

    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRecordingCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRecordingEndpoint.replace(
        ":recording_id",
        this.state.recordingId
      )
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deleteRecording = (recordingId: string) => {
    this.setState({ loading: true, recordingIdToDelete: recordingId });

    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteRecordingCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteRecordingEndpoint.replace(":recording_id", recordingId)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleDeleteSuccess = () => {
    const newRecordings = this.state.allRecordings.filter(
      (recording) => recording.id !== this.state.recordingIdToDelete
    );
    this.setState({ allRecordings: newRecordings });
  };

  handleRoomIdChange = (roomId: string) => {
    this.setState({ roomId: roomId });
  };

  handleRecordingIdChange = (recordingId: string) => {
    this.setState({ recordingId: recordingId });
  };

  handleRecordingDetailModalOpen = (recording: Recording) => {
    this.setState(
      {
        recordingDetail: {
          userId: recording.userId,
          roomId: recording.roomId,
          sessionId: recording.sessionId,
          fileId: recording.fileId,
          resolution:
            recording.file.meta.resolution.width +
            " x " +
            recording.file.meta.resolution.height,
          format: recording.file.meta.format,
          duration: recording.file.meta.duration,
          size: recording.size,
          filePath: recording.filePath,
          fileUrl: recording.fileUrl,
          type: recording.type,
        },
      },
      () => this.setState({ recordingDetailModalOpen: true })
    );
  };

  handleRecordingDetailModalClose = () => {
    this.setState({ recordingDetailModalOpen: false });
  };

  handleDownloadRecordingWeb = () => {
    if (Boolean(this.state.recordingDetail)) {
      window.location.href = this.state.recordingDetail.fileUrl;
    }
  };

  // Customizable Area End
}
